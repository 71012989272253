:root{
  --white:#fff;
  --smoke-white:#f1f3f5;
  --blue:#4169e1;
  --black: #000;
}
.selector{
  display:grid;
  grid-template-columns:repeat(2, 1fr);
  grid-gap:1rem;
}
/* .selector{
  position:relative;
  width:60%;
  background-color:var(--smoke-white);
  height:80px;
  display:flex;
  justify-content:space-around;
  align-items:center;
  border-radius:9999px;
  box-shadow:0 0 16px rgba(0,0,0,.2);
} */
.selecotr-item{
  position:relative;
  flex-basis:calc(70% / 3);
  height:100%;
  display:flex;
  justify-content:center;
  align-items:center;
}
.selector-item_radio{
  appearance:none;
  display:none;
}
.selector-item_label{
  position:relative;
  border: 1px solid rgb(213, 213, 213);
  width:100%;
  text-align:center;
  border-radius:10px;
  line-height:400%;
  font-weight:500;
  transition-duration:.5s;
  transition-property:transform, color, box-shadow;
  transform:none;
  cursor: pointer;
}
.selector-item_radio:checked + .selector-item_label{
  border: 1px solid var(--blue);
  color: var(--black);
  box-shadow:0px 0px 5px #4169e180;
  transform: scale(1.01);



}
@media (max-width:480px) {
.selector{
  width: 90%;
}
}
.selector-item_label span {
  position: absolute;
  color: var(--blue);
  left: 20px;
  background-color: white;
}

.ImageSpan {
  position: absolute;
  color: var(--blue);
  top: 20px;
  left: 20px;
  background-color: white;
  line-height: 1;
  padding: 13px 12px 14px 15px;
  border-radius: 50%;
}

.quiz-container {
  margin-top: 5rem;
}

.next_button {
  padding: 15px 10rem;
  border-radius: 5px;
  background: linear-gradient(90deg, #4169e1 0%, #4fbdff 100%) !important;
  color: #fff;
  font-size: 1.2rem;
  font-weight: 500;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.quiz-body_video{
  width: 65%;
  height: 40%;
  margin-bottom: 0.8rem;
}

.quiz-body_question{
  display: flex;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.16) 1px 1px 6px, rgba(0, 0, 0, 0.23) 1px 1px 6px;
}


.selector-item_input{
  width: 100%;
  border: none;
  outline: none;
  font-size: 1.2rem;
  font-weight: 500;
  padding: 0.5rem;
  border-radius: 5px;
  border: 1px solid rgb(213, 213, 213);
  transition: all 0.3s ease;
  margin: 1rem 0rem;
}

.imageOptions{
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 10px;
}

.quiz-container{
  padding-bottom: 5rem;
}

.photoText{
  width: 100%;
  position: relative;
}

.photoText p {
  font-weight: 500;
  z-index: 1;
  position: absolute;
  background-color: white;
  color: #4169e1;
  bottom: 0;
  width: 95%;
  padding: 0.5rem;
  border-radius: 5px;
  left: 50%;
  transform: translate(-50%, 0);
  /* transform: translate(0, 100%); */

}