
.thankyouBody{
    height: 100vh;
    display: flex;
    vertical-align: middle;
    text-align: center;
    color: white;
}

a {
    color: orange;
}