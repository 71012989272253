.timer_card{
    background: linear-gradient(130deg, #7194ff 0%, #4fbdff 100%) !important;
    border-radius: 50%;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    margin: 20px;
    width: 100px;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 20px;
    color: #000;
    text-align: center;
    position: absolute;
    top: 12px;
    z-index: 1;
    left: 50%;
    transform: translateX(-50%);
}