.login-page {
  width: 360px;
  padding: 8% 0 0;
  margin: auto;
  background-color: transparent;
  border-radius: 10px;
  /* box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2); */
}

.avatar {
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  opacity: 0;
}

.avatar img {
  width: 60px;
  height: 60px;
  border-radius: 50%;

}

.form {
  position: relative;
  z-index: 1;
  max-width: 360px;
  margin: 0 auto 100px;
  padding: 45px;
  text-align: center;
  background: linear-gradient(to right bottom, rgba(255, 255, 255, 0.526), rgba(255, 255, 255, 0.547));
  backdrop-filter: blur(0.075rem);
  box-shadow: 0 0 4px rgb(14 55 63 / 15%);
  border-radius: 20px;
}

.form input[type="text"],
.form input[type="password"] {
  font-family: 'Roboto', sans-serif;
  outline: none;
  width: 100%;
  border: 0;
  margin: 0 0 15px;
  padding: 15px;
  box-sizing: border-box;
  font-size: 14px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.7);
}

.form button {
  font-family: 'Roboto', sans-serif;
  text-transform: uppercase;
  outline: none;
  background-color: #2196F3;
  width: 100%;
  border: 0;
  padding: 15px;
  color: #FFFFFF;
  font-size: 14px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  cursor: pointer;
  border-radius: 5px;
}

.form button:hover,
.form button:active,
.form button:focus {
  background-color: #1976D2;
}

.form h2 {
  color: #333333;
  font-size: 28px;
}
.gameName{
  margin-top: 15px;
}
.form p.message {
  color: #666666;
  font-size: 14px;
  margin-top: 15px;
}

.form p.message a {
  color: #2196F3;
  text-decoration: none;
}

.form p.message a:hover {
  text-decoration: underline;
}

.random_button{
  cursor: pointer;
  padding: 5px 10px;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  color: #000;
  text-align: center;
  position: absolute;
  top: 12px;
  z-index: 1;
  right: 12px;
  line-height: 0;
  transition: 0.3s;
}

.random_button i {
  font-size: 1.5rem;
}

.random_button:hover{
  background-color: #666cff;
}

.random_button:hover i{
  color: #fff;
}


